body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.welcome-area {
  background-image: url("./assets/welcome-bg.png");
  height: 600px;
  position: relative;
  z-index: 1;
  background-position: bottom center;
  background-size: cover;
}

.login-area {
  min-height: 900px;
}

.applogo {
  margin-right: 4px;
}
